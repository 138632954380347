/**
 * vertical 
 */
section {
  // margin-top: 2.5rem;
  margin-top: 3rem;
  position: relative;
}
main > section:first-child,
#start > section:first-child {
  margin-top: 3rem;
  @include media-query(screen-lg) {
    margin-top: 9rem;
  }
}




/**
 * Headlines 
 */
#{headings()} { // [1]
  margin-top: 0;
  margin-bottom: var(--base-spacing-unit);
  // &:first-child {
  //   margin-top: 0;
  // }
}

h1 {
  font-size: 2.22222rem;
  // min-height: 9rem;
  margin-top: 3rem;
  margin-bottom: 1em;

  @include media-query(screen-lg) {
    margin-top: 5rem;
  }
  
}
h2 {
  font-size: 1.555555rem;
  margin-top: 3rem;
  // margin-bottom: 0;

  // + h3 {
  //   margin-top: calc(var(--base-spacing-unit) * -1);
  // }
}
h3 {
  font-size: 1rem;
  line-height: var(--base-line-height) ;
  margin-bottom: 0;
}


[class*="col-"] {
  h1, h2, h3 {
    &:first-child {
      margin-top: 0;
    }
  }
}

// Paragraph, Lists
p, ul, ol {
  margin-top: 0;
  // &:last-child {
  //   margin-bottom: 0;
  // }
}