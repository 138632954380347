// $primary: #ffdf0b;
// MATERIAL DESIGN
@use "@material/theme" with (
  $primary: #ffdf0b,
  // $secondary: #feeae6,
  $secondary: #ffdf0b,
  $on-primary:#4a4a49, // #4a4a49,
  // $on-secondary: #ffdf0b, //#ffdf0b,
  
);



// $mdc-typography-styles-button: (
//   font-size: 16px,
//   text-transform: none,
// );





// @use "@material/icon-button";
@use "@material/fab";
@use "@material/top-app-bar/mdc-top-app-bar";
@use "@material/drawer";
// @use "@material/tab/mdc-tab";
// @use "@material/tab-bar/mdc-tab-bar";
// @use "@material/tab-scroller/mdc-tab-scroller";
// @use "@material/tab-indicator/mdc-tab-indicator";
@use "@material/chips";
@use "@material/chips/mdc-chips";
// @use "@material/card";

@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
@use "@material/button";

@include textfield.core-styles;
@include button.core-styles;

// @include icon-button.core-styles;
@include drawer.core-styles;
// @include card.core-styles;
@include fab.core-styles;


/* https://flatpickr.js.org/examples/#selecting-multiple-dates */
@import "flatpickr/dist/themes/light";
// @import "flatpickr/dist/themes/material_blue";

/**
 * basicLightbox
 * https://github.com/electerious/basicLightbox
 */

$basicLightbox__background: white; // rgba(0, 0, 0, .8); // Background color
$basicLightbox__zIndex: 1000; // Stack order
$basicLightbox__duration: .4s; // Transition duration
$basicLightbox__timing: ease; // Transition timing
@import "basiclightbox/src/styles/main";


/**
 * Glightbox
 * https://biati-digital.github.io/glightbox/
 */
 @import "glightbox/dist/css/glightbox.min";


/** 
  * Erdmann & Freunde - Nutshell
  * BASE Contao Framework
  * erdmann-freunde.de/nutshell/
  *
  * Lizenziert unter MIT OPEN SOURCE 
  * 
  */ 

// SETTINGS
html {
  --mdc-typography-font-family: 'IBM Plex Sans';
  --mdc-typography-body2-font-family: 'IBM Plex Sans';
  --mdc-typography-button-font-family: 'IBM Plex Sans'; 

  // --mdc-theme-primary: red;
}
@import "variables";


// Mixins
@import "mixins/general";
@import "mixins/responsive";
@import "mixins/custom_mixins";

// GENERIC
@import "generic/normalize";
@import "generic/box-sizing";


// BASE
@import "base/type-material-icons";
@import "base/shared";
@import "base/type";
@import "base/grid";
@import "base/layout";
@import "base/layout_header";
@import "base/layout_aside";
@import "base/layout_footer";
@import "base/layout_vertical";


// COMPONENTS
@import "components/mdc_drawer";
@import "components/mdc_chip";
@import "components/mdc_form";
@import "components/mdc_fab_cta";
@import "components/flatpickr";
@import "components/youtube";
@import "components/nav";
@import "components/figur";
@import "components/referenzen";

@import "components/flickity";