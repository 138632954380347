
.figur_filter {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: baseline;
  > span {
    margin-right: 2rem;
    @include media-query(screen-sm-max) {
      display: none;
    }
  }
  @include media-query(screen-md) {
  }
}


/**
 * Chips 
 */
 .mdc-chip-set {
  padding: 0;
  // margin-top: 2rem;
  // margin-bottom: 2rem;
  // justify-content: center;
}


.mdc-chip  {

  /* https://material.io/components/chips/web#style-customization */
  
  // @include chips.set-spacing(3rem);
  // @include chips.fill-color( white );
  // @include chips.fill-color-accessible($color-brand)
  // @include chips.ink-color($color-default);
  // @include chips.selected-ink-color(red);
  // @include chips.outline(2px, solid, $color-gray-light);
  @include chips.height(2.5em);
  // @include chips.horizontal-padding(2rem);

  font-size: 1em;
  // line-height: 1.5;

  /* color */
  background-color: white;
  // &,
  // &:hover {
  //   color: white;
  // }

  &.mdc-chip--selected,
  &.mdc-chip--selected:hover {
    // color: var(--color-brand) !important;
    --mdc-theme-primary: black;
    .mdc-chip__ripple {
      &:before,
      &:after {
        // background-color: var(--color-gray) !important;
        background-color: var(--color-brand) !important;
        opacity: 1 !important;
      }
      & + div {
        z-index: 1 !important;
      }
    }

  }
  &.mdc-chip--selected {
    // background-color: var(--color-gray) !important;
  }
  &.mdc-chip--selected .mdc-chip__ripple::before {
    // opacity: 0 !important;
  }


  /* border */
  &,
  .mdc-chip__ripple {
    border-radius: 0;
  }
  .mid-chip__ripple-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;

    border: 1px solid $color-gray-light;
    z-index: 1;
    
  }

  &.mdc-chip--selected  .mid-chip__ripple-border {
    // border-color: var(--color-brand);
  }
}






