nav {
  display: flex;
  align-self: stretch;

  
  ul {
    display: flex;
    flex-direction: column;
    gap: var(--grid__gutter);
    align-self: stretch;
    

    list-style: none;
    padding: 0;
    margin: 0;

    @include media-query(screen-menu) {
      flex-direction: row;
    }
  }

  li {
    display: flex;
    align-content: center;
  }

  

  a {
    display: flex;
    
    align-self: stretch;
    align-items: center;
    padding: 0 var(--grid__gutter--half);
    text-decoration: none;
    border-bottom: 3px solid transparent;
    transition: border-color .1s ease;
  }
  a.active {
    border-color: var(--color-default);
  }
  a:hover {
    color: var(--color-gray);
    border-color: var(--color-gray);
  }
  
  

  a {
    svg {
      position: relative;
      top: -.2rem;
      height: 1.6rem;
      width: auto;
    }
    svg path {
      transition: fill .1s ease;
    }
    &:hover svg path {
      fill: var(--color-gray);;
    }
  } 

  li:first-child {
    svg {
      display: none;
    }
    @include media-query(screen-menu) {
      span {
        display: none;
      }
      svg {
        display: block;
      }
    }

  }
  
  


  header &  ul {
    // display: flex;
    // &.nav_main li {
    //   margin-right: 2rem;  
    // }

    // &.nav_lang li {
    //   margin-left: .5rem;  
    // }
    // font-size: 1.2em;

    color: var(--color-default);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-big);
  }

  footer & {
    ul {
      display: flex;
      flex-direction: row;
      justify-content: start;
      a {
        color: var(--color-gray-light-2);
        border-bottom: unset;
        &.active {
          color: var(--color-default);
        }
      }
    }
  }
  



  aside & {
    margin-right: 0;
    color: var(--color-gray);
    // line-height: 2;
    text-transform: uppercase;
    letter-spacing: var(--letter-spacing-big);
      

    a {
      // flex-grow: 1;
      padding: 0;
      border-width: 2px;
    }
  }
  
  
  
  
  
  

  
}


aside {
  .logo a {
    outline: none;
  }
}