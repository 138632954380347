.figur_list {
  > h2 {
    font-size: 1.555555rem;
    // font-weight: normal;
    padding-bottom: var(--base-spacing-unit--sm);
    margin-top: 3rem;
    margin-bottom: 0;

  }

  .figur {
    @include border-top();
    // margin-top: 1rem;
    padding-top: 1rem;
  }
  .image {
    @include media-query(screen-sm-max) {
      order: -2;
    }
  }
  .download {
    margin-top: var(--base-spacing-unit);
    @include media-query(screen-sm-max) {
      order: -1;
    }

    .downloads__title {
      display: none;
      @include media-query(screen-sm-max) {
        display: block;
      }
    }
  }


  /* filter */
  [data-has] {
    opacity: 1;
    transform: scale(1);
    transition: all .2s ease-out;
  }
  [data-has].visually_hidden {
    opacity: 0;
    transform: scale(.6);
  }
  [data-has].display_hidden {
    display: none;
  }
}


.figur_details {
  // h1 {
  //   margin-bottom: 4em;
  // }
  // figure {
  //   margin-bottom: var(--base-spacing-unit);
  // }

  .zumversetzenauf {
    margin-top: 4rem;
  }
}