aside {
 
  figure.logo {
    margin: 1.5rem 2rem 1.5rem ;
    a {
      display: block;
    }
    img {
      width: 100%
    }
  }

  nav {
    margin: 2rem 0 2rem 2.4rem;
  }


}

.mdc-drawer__content {
  display: flex;
  flex-direction: column;
  
  nav {
    flex-grow: 1;
    display: flex;

    flex-direction: column;
    justify-content: space-between;

  }
}